var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.track
    ? _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "track-detail",
              params: { id: _vm.track.TrackMetadataID }
            }
          }
        },
        [
          _vm.prefix ? [_vm._v(_vm._s(_vm.prefix) + " ")] : _vm._e(),
          _c("strong", [_vm._v(_vm._s(_vm.track.ProductDetails.Attribution))]),
          _vm._v(
            " - " +
              _vm._s(_vm.track.ProductDetails.Title) +
              " " +
              _vm._s(_vm.track.ProductDetails.VersionTitle) +
              " "
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }