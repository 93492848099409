<template>
  <router-link :to="{ name: 'track-detail', params: { id: track.TrackMetadataID } }" v-if="track">
    <template v-if="prefix">{{ prefix }} </template>
    <strong>{{ track.ProductDetails.Attribution }}</strong> - {{ track.ProductDetails.Title }} {{ track.ProductDetails.VersionTitle }}
  </router-link>
</template>

<script>
  export default {
    name: 'TrackLink',
    components: {
    },
    props: {
      prefix: String, 
      track: [Boolean, Object],
    },
  }
</script>